<template >



  <v-app id="inspire" >
       <!-- Sizes your content based upon application components -->
    <v-main>

        <router-view></router-view>

    </v-main>

<!--    <v-footer app>-->
<!--      &lt;!&ndash; &ndash;&gt;-->


<!--    </v-footer>-->

<!--    <LoadingModal :isLoading="$store.getters.loadingModal"/>-->
<!--    <ErrorSnackBar v-if="$store.getters.snackBar"/>-->
  </v-app>


</template>

<script>
import LoadingModal from "@/components/LoadingModal";
import ErrorSnackBar from "@/components/ErrorSnackBar";
import store from "@/store";
import DomainsPage from "./components/DomainsPage";

export default {
  name: "app",
  components:{LoadingModal, ErrorSnackBar,DomainsPage},
  data() {
    return {
      drawer:false
    }},
  methods:{

    initSettingsFromLogin() {

      var settings = {
        color: {
          mainColor: '#202020',
          sideBar: '#202020',
          itemSideBar: 'secondary'
        }
      };
      this.$store.commit('setSettings', settings)
    },
  },

  created() {
    this.initSettingsFromLogin();
    // this.$router.replace({ name: "user-view", params: {id:"123"}, query: {q1: "q1"} })
    this.$store.commit('setSnackBarObj', {text: 'Hello Monoversian ', icon : 'mdi-alien'});
    this.$store.commit('setSnackBar', true)
  }
};
</script>

<style>
  @import './assets/styles/main.css';

  .v-data-table-header th {
  white-space: nowrap !important;
}


  .centerScreen {
  bottom: 0;
  display: block;
  font-size: 32px;
  left: 50%;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
