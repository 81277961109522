import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exploregrid"},[_c('div',{staticClass:"exploremonoverse"},[_vm._v("Explore our Domains")]),_c(VRow,{staticClass:"domainsfilterrow"},[_c('div',{staticClass:"domainsfilter"},[_c(VSelect,{ref:"select",staticStyle:{"border-radius":"10px"},attrs:{"items":_vm.domainFilters,"dark":"","background-color":"black","item-text":"description","outlined":"","item-color":"#03DAC6","color":"#03DAC6","return-object":"","append-icon":"expand_more","menu-props":{
                  maxHeight: '80%', overflowX: true
                                                    }},on:{"change":_vm.changeFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                                    var item = ref.item;
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c(VListItem,_vm._g({staticStyle:{"background-color":"black","color":"#03DAC6"}},on),[_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticStyle:{"color":"#03DAC6"}},[_vm._v(_vm._s(item.description))])])],1)],1)]}}]),model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }