import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        login: '',
        templates: [],
        boxedContainers: [],
        boxedContainersForEditing: [],
        fields: [],
        fieldTypes: [],
        loadingModal: false,
        domainsPaging: [],
        templatesList: [],
        deleteDialog: false,
        editOrAddDomainModal: false,
        settings: {},
        mainBlocks:[],
        domainsInfo:[],
        allDomains:[],
        tlds:[],
        blockOwners:[],
        snackBar: false,
        snackBarObj: {},
    },
    getters: {
        login: state => state.login,
        templates: state => state.templates,
        boxedContainers: state => state.boxedContainers,
        boxedContainersForEditing: state => state.boxedContainersForEditing,
        fields: state => state.fields,
        fieldTypes: state => state.fieldTypes,
        loadingModal: state => state.loadingModal,
        domainsPaging: state => state.domainsPaging,
        templatesList: state => state.templatesList,
        deleteDialog: state => state.deleteDialog,
        editOrAddDomainModal: state => state.editOrAddDomainModal,
        settings: state => state.settings,
        mainBlocks: state => state.mainBlocks,
        domainInfo: state => state.domainsInfo,
        tlds: state => state.tlds,
        blockOwners: state => state.blockOwners,
        snackBar: state => state.snackBar,
        snackBarObj: state => state.snackBarObj,
        allDomains: state => state.allDomains,
    },
    mutations: {
        setLogin: function (state, loginData) {
            state.login = loginData
        },
        setTemplates: function (state, data) {
            state.templates = data
        },
        setBoxedContainers: function (state, data) {
            state.boxedContainers = data
        },
        setBoxedContainersForEditing: function (state, data) {
            state.boxedContainersForEditing = data
        },
        setFields: function (state, data) {
            state.fields = data
        },
        setLoadingModal: function (state, loadingModalData) {
            state.loadingModal = loadingModalData
        },
        setDomainsPaging: function (state, data) {
            state.domainsPaging = data
        },
        setFieldTypes: function (state, data) {
            state.fieldTypes = data
        },
        setTemplatesList: function (state, data) {
            state.templatesList = data
        },
        setDeleteDialog: function (state, data) {
            state.deleteDialog = data
        },
        setEditOrAddDomainModal: function (state, data) {
            state.editOrAddDomainModal = data
        },
        setSettings: function (state, data) {
            state.settings = data
        },
        setMainBlocks: function (state, data) {
            state.mainBlocks = data
        },
        setDomainsInfo: function (state, data) {
            state.domainsInfo = data
        },
        setTlds: function (state, data) {
            state.tlds = data
        },
        setBlockOwners: function (state, data) {
            state.blockOwners = data
        },
        setSnackBar: function (state , snackBarData) {
            state.snackBar = snackBarData
        },
        setSnackBarObj: function (state, snackBarObjData) {
            state.snackBarObj = snackBarObjData
        },
        setAllDomains: function (state, data) {
            state.allDomains = data
        },
    },
    actions: {},
    modules: {}


});
