import route from './router';
// import utils from './utilities';
import store from './store'

let properties = {

    getErrorMessage(response) {

        var message = response.message;

       if ((message.includes("username.existing"))){
            store.commit('setSnackBarObj', {text: 'usernameExist'});
            store.commit('setSnackBar', true)
        }
        else if (message.includes("invalid.user.credentials")) {
            store.commit('setSnackBarObj', {text: 'invalidUserCredentials'});
            store.commit('setSnackBar', true)
        }
        else if (message.includes("duplicate.entity.violation")){
            store.commit('setSnackBarObj', {text: 'duplicatedRecord'});
            store.commit('setSnackBar', true)
        }
        else if (message.includes("Required String parameter 'creatorUserName' is not present")){
            store.commit('setSnackBarObj', {text: 'emptyCreatorUser'});
            store.commit('setSnackBar', true)
        }
        else if (message.includes("user.not.found")) {

                store.commit('setSnackBarObj', {text: i18n.t('userNotFound')});
            store.commit('setSnackBar', true)
        }
        else if (message.includes("data.integrity.violation")) {
            store.commit('setSnackBarObj', {text: response.details});
            store.commit('setSnackBar', true)
        }
        else {
            if(response.details){
                store.commit('setSnackBarObj', {text: response.details});
            }else {
                store.commit('setSnackBarObj', {text: message});
            }
            store.commit('setSnackBar', true)
        }
        // return "Ο λογαριασμός σας δεν είναι ενεργός. Παρακαλώ επικοινωνήστε με το Γραφείο Αρωγής Χρηστών";
    }
};

export default properties