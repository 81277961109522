<template>
    <div class="exploregrid">
        <div class="exploremonoverse">Explore our Domains</div>
        <v-row class="domainsfilterrow">
            <div class="domainsfilter" >
                <v-select

                        style="border-radius: 10px;"
                        ref="select"
                        :items="domainFilters"
                        dark
                        background-color="black"
                        item-text="description"
                        outlined
                        item-color="#03DAC6"
                        color="#03DAC6"
                        return-object
                        v-model="selectedFilter"
                        v-on:change="changeFilter"
                        append-icon="expand_more"
                        :menu-props="{
                      maxHeight: '80%', overflowX: true
                                                        }"
                >
                    <template v-slot:item="{item, on, attrs}">
                        <v-list-item v-on="on" style="background-color: black; color: #03DAC6;">

                            <v-list-item-content>
                                <v-list-item-title>
                                    <span style="color:#03DAC6;">{{ item.description }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                </v-select>
            </div>

        </v-row>
    </div>

</template>

<script>

    import request from "@/requests";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import headerComponent from "@/components/reusable/header";
    import explore from "@/components/reusable/exploreGrid";



    export default {
        name: "DomainsPage",
        components: {DomainsGrid,headerComponent,explore},

        data: () => ({

                selectedFilter: {
                    id: 1,
                    description : 'Premium'
                },
                domainFilters: [
                    {
                        id: 1,
                        description : 'Premium'
                    },
                    {
                        id: 2,
                        description : 'Offer'
                    },
                    {
                        id: 3,
                        description : 'Popularity'
                    },
                    {
                        id: 4,
                        description : 'Lowest Price'
                    },
                    {
                        id: 5,
                        description : 'Highest Price'
                    },
                    {
                        id: 6,
                        description : 'Shortest Length'
                    },
                    {
                        id: 7,
                        description : 'Longest Length'
                    },
                ]

            }
        ),

        methods: {












            changeFilter(filter){
                if(filter.id === 1){
                    this.$store.getters.domainInfo.sort((a, b) => a.premium - b.premium).reverse();
                }
                else if(filter.id === 2){

                  this.$store.getters.domainInfo.sort((a, b) => a.offer - b.offer).reverse();
                }
                else if(filter.id === 3){
                  console.log("filter popular on exploreGrid");
                  this.$store.getters.domainInfo.sort((a, b) => a.popular - b.popular).reverse();
                }
                // lowest price
                else if(filter.id === 4){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                }
                // highest price
                else if(filter.id === 5){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)).reverse();
                }
                else if(filter.id === 6){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length);
                }
                else if(filter.id === 7){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length).reverse();
                }
            }


        },


        computed: {

        },

        watch: {

        },



        created() {


        },

    }


</script>

<style lang="css">

    @import "./../../assets/styles/main.css";



    .pagination button {
        /*background-color: #eaeaea;*/
        border: none;
        color: #333;
        padding: 8px 16px;
        margin: 0 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .pagination button:hover {
        background-color: #ccc;
    }

    .pagination button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .pagination button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .pagination button:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .pagination .page-numbers {
        display: flex;
        align-items: center;
    }

    .pagination .page-numbers button {
        background-color: transparent;
        border: none;
        color: #333;
        padding: 8px;
        cursor: pointer;
    }

    .pagination .page-numbers button.active {
        font-weight: bold;
        text-decoration: underline;
        color: #A5A5A5;
    }

    .v-menu__content {
        position: absolute;
        display: inline-block;
        max-width: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        contain: content;
        -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    div.v-menu__content.theme--light.menuable__content__active{
        overflow: hidden;
        border: thin solid;
        border-radius: 15px;
        border-color: #03DAC6;
        height: 345px;
    }

    div.v-select__selection.v-select__selection--comma {
        color: #03DAC6 !important;
    }



</style>
