import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('headerComponent'),_c('div',{staticClass:"domainsbackgroun"},[_c('explore'),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"chips"},[_c(VSlideGroup,{staticStyle:{"width":"100%"},attrs:{"multiple":"","show-arrows":"always always"},scopedSlots:_vm._u([{key:"next",fn:function(){return [_c(VIcon,{staticStyle:{"color":"white !important","margin-top":"15px"}},[_vm._v("chevron_right")])]},proxy:true},{key:"prev",fn:function(){return [_c(VIcon,{staticStyle:{"color":"white !important","margin-top":"15px"}},[_vm._v("chevron_left")])]},proxy:true}])},_vm._l((_vm.mainBlock),function(chip){return _c(VSlideItem,{key:chip.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [(!!_vm.selectedChip && _vm.selectedChip.id === chip.id)?_c(VBtn,{staticClass:"b-chip",staticStyle:{"text-transform":"unset !important"},attrs:{"color":chip.color,"text-color":"#FFFFFF","input-value":active},on:{"click":function($event){return _vm.handleChipClick(chip)}}},[_c('span',{staticClass:"v-bnt-text"},[_vm._v(" "+_vm._s(chip.description.charAt(0)+chip.description.slice(1).toLowerCase())+" ")])]):_c(VBtn,{staticClass:"b-chip",staticStyle:{"text-transform":"unset !important"},attrs:{"color":"#FFFFFF","text-color":chip.color,"input-value":active},on:{"click":function($event){return _vm.handleChipClick(chip)}}},[_c('span',{staticClass:"v-bnt-text",style:({color: chip.color})},[_vm._v(" "+_vm._s(chip.description.charAt(0)+chip.description.slice(1).toLowerCase())+" ")])])]}}],null,true)})}),1)],1),_c('DomainsGrid',{attrs:{"returnPage":_vm.selectedChip}}),_c('div',[_c('mainfooter')],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }