<template>
    <div>
        <div class="headerOpen" v-if="isBurgerOpen">
            <div class="navbar" style="border-bottom-style: solid; border-width: thin; color: #898989">
                <div class="logo">
                    <a class="logolink" href="/#">
                        <img class="logoimage" src="@/assets/images/header/icon.svg" alt="monoverseicon">
                        <img class="logotext" src="@/assets/images/header/monoverse.svg" alt="monoverseicon">
                    </a>
                </div>
                <div class="burger">
<!--                    -->
                  <v-btn x-large @click="clickBurger" icon aria-label="burger">
<!--                    <v-icon x-large v-if="!isBurgerOpen">menu</v-icon>-->
                      <img class="burgericon" v-if="!isBurgerOpen" src="@/assets/images/burger.svg">
                    <v-icon x-large v-else>close</v-icon>
                  </v-btn>

                </div>
                <div class="menu">
                    <a href="/verse"><div class="about" >.verse</div></a>
                    <a href="/domains"><div class="blocks" >Domains</div></a>
                    <a href="/blocks"><div class="blocks" >Blocks</div></a>
                    <a href="/branding"><div class="blocks">Branding</div></a>
                    <a href="/about"><div class="about" >About</div></a>
                    <a href="/contact"><div class="about" >Contact</div></a>
<!--                    <a href="/domains"><div class="domainsmenubutton">Domains</div></a>-->
                    <a href="/join"><div class="joinmenubutton">
                        <div class="joinmenubuttontext">Join</div>
                    </div>
                    </a>
                </div>


            </div>


            <div v-if="isBurgerOpen" class="mobileMenu">
                <div class="bordermenu"><a href="/verse" ><div class="about">.verse</div></a></div>
                <div class="bordermenu"><a href="/domains" ><div class="about">Domains</div></a></div>
                <div class="bordermenu"><a href="/blocks" ><div class="about">Blocks</div></a></div>
                <div class="bordermenu"><a href="/branding" ><div class="about">Branding</div></a></div>
                <div class="bordermenu"><a href="/about" ><div class="blocks">About</div></a></div>
                <div class="bordermenu"><a href="/contact" ><div class="blocks">Contact</div></a></div>
                <div ><a href="/join" style="display:grid;margin:auto;"><div class="joinmenubutton">
                    <div class="joinmenubuttontext">Join Monoverse</div>
                </div>
                </a></div>
            </div>
            <div v-if="isBurgerOpen" class="bigMobileMenu">
                <div class="bordermenu"><a href="/verse" style="margin:auto;"><div class="about">.verse</div></a></div>
                <div class="bordermenu"><a href="/domains" style="margin:auto;"><div class="about">Domains</div></a></div>
                <div class="bordermenu"><a href="/blocks" style="margin:auto;"><div class="about">Blocks</div></a></div>
                <div class="bordermenu"><a href="/branding" style="margin:auto;"><div class="about">Branding</div></a></div>
                <div class="bordermenu"><a href="/about" style="margin:auto;"><div class="blocks">About</div></a></div>
                <div class="bordermenu"><a href="/contact" style="margin:auto;"><div class="blocks">Contact</div></a></div>
                <div style="padding: 0 32px"><a href="/join" style="display:grid;"><div class="joinmenubutton">
                    <div class="joinmenubuttontext">Join Monoverse</div>
                </div>
                </a></div>
            </div>
        </div>
        <div class="header" v-else>
            <div class="navbar">
                <div class="logo">
                    <a class="logolink" href="/#">
                        <img class="logoimage" src="@/assets/images/header/icon.svg" alt="monoverseicon">
                        <img class="logotext" src="@/assets/images/header/monoverse.svg" alt="monoverse">
                    </a>
                </div>
                <div class="burger">
                    <!--                    <img class="burgericon" src="@/assets/images/burger.svg" >-->
                    <v-btn x-large @click="clickBurger" icon aria-label="burger">
<!--                        <v-icon x-large v-if="!isBurgerOpen">menu</v-icon>-->
                        <img class="burgericon" v-if="!isBurgerOpen" src="@/assets/images/burger.svg">
                        <v-icon x-large v-else>close</v-icon>
                    </v-btn>

                </div>
                <div class="menu">
                    <a href="/verse"><div class="about" >.verse</div></a>
                    <a href="/domains"><div class="domainsmenubutton" >Domains</div></a>
                    <a href="/blocks"><div class="blocks" >Blocks</div></a>
                    <a href="/branding"><div class="blocks">Branding</div></a>
                    <a href="/about"><div class="about" >About</div></a>
                    <a href="/contact"><div class="about" >Contact</div></a>
                    <a href="/join"><div class="joinmenubutton">
                        <div class="joinmenubuttontext">Join</div>
                    </div>
                    </a>
                </div>
                <div v-if="isBurgerOpen" class="mobileMenu">
                    <a href="/about"><div class="about">About</div></a>
                    <a href="/blocks"><div class="blocks">Blocks</div></a>
                    <a href="/domains"><div class="domainsmenubutton">Domains</div></a>
                    <a href="/join"><div class="joinmenubutton">
                        <div class="joinmenubuttontext">Join Monoverse</div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {eventBus} from "@/main";




    export default {
        name: "header",
      data: () => ({
        isBurgerOpen: false
      }),
      methods:{

          clickBurger(){
            this.isBurgerOpen = !this.isBurgerOpen
          }

      }
    }

</script>


<style lang="css">


    @import "./../../assets/styles/mainTablet.css";
    @import "./../../assets/styles/main.css";


    @import "./../../assets/styles/mainBigMobile.css";


</style>




