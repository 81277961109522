<template>
  <v-dialog persistent
            @keydown.esc="closeDialog"
            v-model="$store.getters.deleteDialog"
            :retain-focus="false"
            width="400">
    <v-card>
      <v-card-title class="primary">
        <span class="headline white--text">{{'delete'}}</span>
      </v-card-title>

      <v-card-text>
        <h3 class="mt-4">
          {{'Are you sure?'}}
        </h3>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="closeDialog">
          {{'cancel'}}
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="deleteItem">
          {{'delete'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {eventBus} from "@/main";
export default {
  name: "DeleteDialog",
  data: () => ({
  }),
  props: {
    eventName: String
  },

  methods: {

    closeDialog() {

      this.$store.commit('setDeleteDialog', false)
    },

    deleteItem(){

      this.$store.commit('setDeleteDialog', false);
      eventBus.$emit(this.eventName);
    }
  }
}
</script>

<style scoped>

</style>
