import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","retain-focus":false,"width":"400"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDialog($event)}},model:{value:(_vm.$store.getters.deleteDialog),callback:function ($$v) {_vm.$set(_vm.$store.getters, "deleteDialog", $$v)},expression:"$store.getters.deleteDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary"},[_c('span',{staticClass:"headline white--text"},[_vm._v(_vm._s('delete'))])]),_c(VCardText,[_c('h3',{staticClass:"mt-4"},[_vm._v(" "+_vm._s('Are you sure?')+" ")])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s('cancel')+" ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s('delete')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }