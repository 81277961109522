import axios from 'axios';
import store from './store';
import prop from './properties';

export const BASE_URL = process.env.VUE_APP_API_ENDPOINT;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
var requests = {


    getRequest(token, urlPart, id, callBack) {

        axios.get( BASE_URL + urlPart + id, {
            headers: {
                "Content-Type": "application/json",
            }})
            .then((response => {
                callBack(response.data);
            }))
            .catch(((error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data.message);
            })))
    },

    reqRedirectRequest(token, urlPart, obj, callBack) {

        axios.post( BASE_URL + urlPart ,obj , {
            headers: {
                "Content-Type": "application/json"
            }})
            .then((response => {
                // console.log("callback log " + response.data);
                // console.log("callback log " + response.headers.location);
                window.location.href = response.data;
                //callBack(response.data);
            }))
            .catch(((error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data.message);
            })))
    },


    postRequest(token, urlPart, requestObj, callBack) {

        axios.post( BASE_URL  + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch((error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data.message);
            }))
    },


    putRequest(token, urlPart, requestObj, callBack) {
        // console.log("PUT");

        axios.put( BASE_URL  + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch((error =>{
                store.commit('setLoadingModal', false);
                prop.getErrorMessage(error.response.data.message);
            }))
    },
    deleteRequest(token, urlPart, callBack) {

        axios.delete( BASE_URL  + urlPart, {
            headers: {
                'Content-Type': 'application/json',
           }
        })
            .then((response) =>{
                callBack(response.data)
            })
            .catch((error =>{
                store.commit('setLoadingModal', false);
                console.log(error);
                prop.getErrorMessage(error.response.data.message);
            }))
    },

    async uploadFilePostAsync(token, url, formData,uploadFilePostAsyncRespone){
        const response = await axios.post(BASE_URL  + url,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 300000
            })
        uploadFilePostAsyncRespone(response.data)
    },
};
export default requests
