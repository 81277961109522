<template>
  <v-snackbar v-model="isShow">
    <v-row align="center" justify="center">
    {{ $store.getters.snackBarObj.text }}
    <v-icon v-if="$store.getters.snackBarObj.icon">
      {{$store.getters.snackBarObj.icon}}
    </v-icon>
<v-spacer></v-spacer>
<v-btn
        color="red"
        text
        @click="closeSnackBar">
      Close
    </v-btn>
  </v-row>
  </v-snackbar>
</template>

<script>
import HelloIcon from "@/assets/HelloIcon"
export default {
  name: "ErrorSnackBar",

  data: () => ({
    isShow: true
  }),
  methods: {

    closeSnackBar() {

      this.$store.commit('setSnackBar', false);
    }
  },
  watch: {
    'isShow': function(newValue, oldValue) {
      if (!newValue) {
        this.$store.commit('setSnackBar', false);
      }
    }
  }
}
</script>

<style scoped>

</style>