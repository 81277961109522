<template>
    <div>
        <div class="footer">
            <div class="footerheader">
                <h4>Monoverse is set to open the <b>world's first</b> Bricks & Mortar <b>Domain Name Store</b> in <b>London</b> by the end of 2024</h4>
            </div>
            <div class="footernavbar">


                <div class="footernavbar-2">
                    <img class="footerlogoimage" src="@/assets/images/footerlogo.svg" alt="footerlogoimage"/>
                    <a class="footermenucontactLink" href="/verse"><div class="footermenucontact">.verse</div></a>
                    <a class="footermenucontactLink" href="/branding"><div class="footermenucontact">Branding</div></a>
                    <a class="footermenucontactLink" href="/cookie-policy"><div class="footermenucontact">Cookie Policy</div></a>
                    <a  class="footermenucontactLink" href="/domains"><div class="footermenucontact">Domains</div></a>
                    <a class="footermenucontactLink" href="/about"><div class="footermenucontact">About</div></a>
                    <a class="footermenucontactLink" href="/privacy"><div class="footermenucontact">Privacy Settings</div></a>
                    <a class="footermenucontactLink" href="/blocks"><div class="footermenucontact">Blocks</div></a>
                    <a class="footermenucontactLink" href="/contact"><div class="footermenucontact">Contact</div></a>
                    <div class="linkedinmob"><a href="https://www.linkedin.com/company/monoversedotcom/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="34" viewBox="0 0 36 34" fill="none">
                            <rect x="32" y="4" width="4" height="26" fill="white"/>
                            <rect y="4" width="4" height="26" fill="white"/>
                            <rect x="16" y="13" width="4" height="14" fill="white"/>
                            <rect x="4" y="4" width="4" height="28" transform="rotate(-90 4 4)" fill="white"/>
                            <rect x="4" y="34" width="4" height="28" transform="rotate(-90 4 34)" fill="white"/>
                            <rect x="8" y="7" width="4" height="4" fill="white"/>
                            <rect x="8" y="13" width="4" height="14" fill="white"/>
                            <rect x="20" y="13" width="4" height="4" fill="white"/>
                            <rect x="24" y="17" width="4" height="10" fill="white"/>
                        </svg></a></div>
                </div>


                <div class="copyright" >
                    <div class="linkedin"><a href="https://www.linkedin.com/company/monoversedotcom/"><svg xmlns="http://www.w3.org/2000/svg" width="36" height="34" viewBox="0 0 36 34" fill="none">
                        <rect x="32" y="4" width="4" height="26" fill="#808080"/>
                        <rect y="4" width="4" height="26" fill="#808080"/>
                        <rect x="16" y="13" width="4" height="14" fill="#808080"/>
                        <rect x="4" y="4" width="4" height="28" transform="rotate(-90 4 4)" fill="#808080"/>
                        <rect x="4" y="34" width="4" height="28" transform="rotate(-90 4 34)" fill="#808080"/>
                        <rect x="8" y="7" width="4" height="4" fill="#808080"/>
                        <rect x="8" y="13" width="4" height="14" fill="#808080"/>
                        <rect x="20" y="13" width="4" height="4" fill="#808080"/>
                        <rect x="24" y="17" width="4" height="10" fill="#808080"/>
                    </svg></a></div>
                        <div class="copyrigttext">© Monoverse.com 2023. All rights reserved.</div>

                </div>
                </div>
            </div>
        <img class="footerPatern" src="./../assets/images/footerPatternv2.svg" width="100%" alt="footerPatern" >

<!--        <img class="footerPatern5" src="./../assets/images/footerPatern5.svg" width="100%">-->
        </div>
</template>


<script>
    import {eventBus} from "@/main";


    export default {
        name: "footer",}

</script>


<style>
    @import "./../assets/styles/mainTablet.css";

</style>
