<template>
    <div>
        <div>
        <headerComponent></headerComponent>
            <div class="domainsbackgroun">
                <explore></explore>

                <div class="separator"></div>

<!--                <div class="chips">-->
<!--                    <v-chip v-for="chip in mainBlock" :key="chip.id"-->
<!--                            @click="handleChipClick(chip)"-->
<!--                            :color="chip.color"-->
<!--                            text-color="#ffffff"-->
<!--                            class="b-chip" >-->
<!--                        {{ chip.description.charAt(0)+chip.description.slice(1).toLowerCase() }}-->
<!--                    </v-chip>-->
<!--                </div>-->

              <!-- EDW KSEKINAEI TO NEO CHIPS MENU  -->
              <div class="chips">
                <v-slide-group multiple show-arrows="always always" style="width:100%">

                  <template v-slot:next>
                    <v-icon style="color: white !important; margin-top: 15px;" >chevron_right</v-icon>
                  </template>
                  <template v-slot:prev>
                    <v-icon style="color: white !important; margin-top: 15px;" >chevron_left</v-icon>
                  </template>
                  <v-slide-item v-slot:default="{ active, toggle }"
                                v-for="chip in mainBlock" :key="chip.id"
                  >

                    <v-btn v-if="!!selectedChip && selectedChip.id === chip.id"
                            @click="handleChipClick(chip)"
                            :color="chip.color"
                            text-color="#FFFFFF"
                            class="b-chip"
                            :input-value="active"
                            style="text-transform: unset !important;"
                    >


                        <span class="v-bnt-text" >
                            {{ chip.description.charAt(0)+chip.description.slice(1).toLowerCase() }}
                        </span>

<!--                      <v-icon style="margin-left: 4px;" >close</v-icon>-->

                    </v-btn>
                      <v-btn v-else
                             @click="handleChipClick(chip)"
                             color="#FFFFFF"
                             :text-color="chip.color"
                             class="b-chip"
                             :input-value="active"
                             style="text-transform: unset !important;"
                      >


                        <span class="v-bnt-text" v-bind:style="{color: chip.color}">
                            {{ chip.description.charAt(0)+chip.description.slice(1).toLowerCase() }}
                        </span>

                          <!--                      <v-icon style="margin-left: 4px;" >close</v-icon>-->

                      </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </div>
                <DomainsGrid :returnPage="selectedChip" />
                <div> <mainfooter></mainfooter></div>
            </div>
        </div>

    </div>




</template>


<script>

    import request from "@/requests";
    import DomainsGrid from "@/components/reusable/DomainsGrid";
    import headerComponent from "@/components/reusable/header";
    import explore from "@/components/reusable/exploreGrid";

    import mainfooter from "@/components/footer";



    export default {
        name: "DomainsPage",
        components: {DomainsGrid,headerComponent,explore,mainfooter},

        data: () => ({

            chipTitle: '',
            selectedChip: null,
            mainBlock:{
                id:"",
                description:""
            },
                selectedFilter: {
                    id: 1,
                    description : 'Premium'
                },
                domainFilters: [
                    {
                        id: 1,
                        description : 'Premium'
                    },
                    {
                        id: 2,
                        description : 'Offer'
                    },
                    {
                        id: 3,
                        description : 'Popularity'
                    },
                    {
                        id: 4,
                        description : 'Lowest Price'
                    },
                    {
                        id: 5,
                        description : 'Highest Price'
                    },
                    {
                        id: 6,
                        description : 'Shortest Length'
                    },
                    {
                        id: 7,
                        description : 'Longest Length'
                    },
                    {
                        id: 8,
                        description : 'Longest Length'
                    },
                ]
            }
           ),

        methods: {



            getBlock(){
                request.getRequest('', 'api/main-block', '', this.getBlockCallBack);
            },

            getBlockCallBack(response) {

                this.mainBlock=response;

                let i = 0;
                for (i=0; i<this.mainBlock.length; i++){
                    if (this.mainBlock[i].description.toLowerCase() === this.defaultSelected.parameters.split("/")[1].toLowerCase()){
                        this.title=this.mainBlock[i].description
                        this.description = this.mainBlock[i].body
                    }else{
                    }
                }
            },

            handleChipClick(chip) {

              this.chipTitle = chip.description;

              if(!!this.selectedChip && this.selectedChip.id == chip.id){
                this.$store.commit('setDomainsInfo', this.$store.getters.allDomains)
                this.selectedChip = null
              }else {
                this.$store.commit('setDomainsInfo', this.$store.getters.allDomains.filter(item => item.mainBlock.id === chip.id))
                this.selectedChip = chip
              }

            },

            changeFilter(filter){
                if(filter.id === 1){
                    this.$store.getters.domainInfo.filter(item => !!item.premium)
                }
                else if(filter.id === 2){

                  this.$store.getters.domainInfo.filter(item => !!item.offer)
                }
                else if(filter.id === 3){
                  console.log(this.$store.getters.domainInfo);
                  console.log("filter popular domainspage");
                  this.$store.getters.domainInfo.filter(item => !!item.popular)

                }
                // lowest price
                else if(filter.id === 4){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                }
                // highest price
                else if(filter.id === 5){
                    this.$store.getters.domainInfo.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)).reverse();
                }
                else if(filter.id === 6){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length);
                }
                else if(filter.id === 7){
                    this.$store.getters.domainInfo.sort((a, b) => a.description.length - b.description.length).reverse();
                }
                else if(filter.id === 8){
                     this.$store.getters.domainInfo.filter(item => item.mainBlock.description === this.chipTitle)
                }
            }


        },


        computed: {

        },

        watch: {

        },



        created() {


            this.getBlock()
        },
    }


</script>

<style lang="css">

    @import "./../assets/styles/main.css";
    @import "./../assets/styles/mainTablet.css";

    .v-text-field__input{
        color: aliceblue;

    }


    .v-btn__content{
        color: aliceblue;
    }
    .v-main__wrap{
        text-decoration-color: antiquewhite;
        background: #202020;
        color: aliceblue !important;
    }

    .domainNameOnButton{
      position: absolute;
      width: 144px;
      height: 18px;
      left: 50px;
      top: 297px;

      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;

      color: #FFFFFF;

    }
    .v-list.v-select-list.v-sheet.theme--light.theme--light{
        background-color: black;
        height: 300px;
    }

    .v-menu__content.theme--light.menuable__content__active{
        max-height: 304px;
        /* min-width: 765px; */
        top: 12px;
        left: 12px;
        transform-origin: left top;
        z-index: 8;
        height: 300px;
    }

    .domainNameOnButton{
        position: absolute;
        width: 144px;
        height: 18px;
        left: 50px;
        top: 297px;

        font-family: 'Arial';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;

        color: #FFFFFF;

    }

    .pagination {

        height: 32px;
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 64px;
    }

    .pagination button {
        /*background-color: #eaeaea;*/

        border: none;
        color: #333;
        height: 32px;
        padding: 8px 16px;
        margin: 0 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }



    .pagination button:hover {

        height: 32px;
        background-color: transparent;
    }

    .pagination button:disabled {

        height: 32px;
        cursor: not-allowed;
        opacity: 0.5;
    }
    .pagination button:enabled {

        height: 32px;
    }

    .pagination button:first-child {

        height: 32px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .pagination button:last-child {

        height: 32px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .pagination .page-numbers {

        height: 32px;
        display: flex;
        align-items: center;
    }

    .pagination .page-numbers button {

        height: 32px;
        background-color: transparent;
        border: none;
        color: #333;
        padding: 8px;
        cursor: pointer;
    }

    .pagination .page-numbers button.active {

        height: 32px;
        font-weight: bold;
        text-decoration: underline;
        color: #A5A5A5;
    }

    .v-menu__content {
        position: absolute;
        display: inline-block;
        max-width: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        contain: content;
        -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }

    div.v-menu__content.theme--light.menuable__content__active{
        overflow: hidden;
        border: thin solid;
        border-radius: 15px;
        border-color: #03DAC6;
        height: 345px;
    }

    div.v-select__selection.v-select__selection--comma {
        color: #03DAC6 !important;
    }



</style>



<!--<template>-->
<!--&lt;!&ndash;    Here goes a form&ndash;&gt;-->
<!--    <div>-->
<!--        Here goes a form-->
<!--&lt;!&ndash;        <DomainInfo></DomainInfo>&ndash;&gt;-->

<!--    </div>-->



<!--</template>-->

<!--<script>-->
<!--    import TutorialDataService from "../services/TutorialDataService";-->
<!--    import request from '../requests';-->
<!--    import requests from "../requests";-->
<!--    import {eventBus} from "@/main";-->
<!--    import DomainInfo from "./reusable/DomainInfo";-->
<!--    export default {-->

<!--        name: "form",-->
<!--        components: {Form},-->
<!--        data() {-->
<!--            return {-->
<!--                valid: true,-->
<!--                tutorial: {-->
<!--                    id: null,-->
<!--                    title: "",-->
<!--                    description: "",-->
<!--                    content: "",-->
<!--                    published: false-->
<!--                },-->
<!--                radioGroup: 1,-->
<!--                radioOptions: [-->
<!--                    {id: 1, description: 'Flat'},-->
<!--                    {id: 2, description: 'House'}-->
<!--                ],-->
<!--                category: 1,-->
<!--                myRules: {-->
<!--                    required: [value => !!value || "Required."]-->
<!--                },-->
<!--                submitted: false-->
<!--            };-->
<!--        },-->
<!--        props: {-->
<!--            editedIndex: Number,-->
<!--            disableFields: Boolean,-->
<!--        },-->

<!--        methods: {-->


<!--            created() {-->
<!--                this.getFK();-->

<!--                this.$router.replace({name: "join", params: {id: "123"}, query: {q1: "q1"}})-->

<!--            }-->

<!--        }-->
<!--    };-->
<!--</script>-->

<!--<style>-->
<!--    .submit-form {-->
<!--        max-width: 300px;-->
<!--        margin: auto;-->
<!--    }-->
<!--</style>-->
