import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/thank_you",
      alias: "/thank_you?*",
      name: "thank_you",
      component: () => import("./components/Thank")
    },
    {
      path: "/email-received",
      alias: "/email-received",
      name: "email-received",
      component: () => import("./components/ThankMail")
    },
    {
      path: "/privacy",
      alias: "/privacy",
      name: "privacy",
      component: () => import("./components/Privacy")
    },
    {
      path: "/verse",
      alias: "/verse",
      name: "verse",
      component: () => import("./components/Verse")
    },{
      path: "/branding",
      alias: "/branding",
      name: "branding",
      component: () => import("./components/Branding")
    },
    {
      path: "/cookie-policy",
      alias: "/cookie-policy",
      name: "cookie-policy",
      component: () => import("./components/Cookies")
    },
    {
      path: "/about",
      alias: "/about",
      name: "about",
      component: () => import("./components/About")
    },
    // {
    //   path: "/about",
    //   alias: "/about",
    //   name: "about",
    //   component: () => import("./components/Join")
    // },
    {
      path: "/domains",
      alias: "/domains",
      name: "Domains",
      component: () => import("./components/DomainsPage")
    },
    {
      path: "/contact",
      alias: "/contact",
      name: "Contact",
      component: () => import("./components/ContactPage")
    },
    {
      path: "/join",
      alias: "/join",
      name: "join",
      component: () => import("./components/Join")
    },
    {
      path: "/blocks",
      alias: "/blocks?*",
      name: "Blocks",
      component: () => import("./components/BlocksPage")
    },
    {
      path: "/checkout",
      alias: "/checkout?*",
      name: "checkout",
      component: () => import("./components/Checkout")
    },

    {
      path: "/",
      alias: "/*",
      name: "Home",
      component: () => import("./components/homePage")
    },





  ]
});
